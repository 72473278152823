<template>
    <div class="card card-height">
        <div class="table-responsive">
            <table class="table custom-table-padding">
                <thead>
                    <tr>
                        <th class="name">Name</th>
                        <th>Address</th>
                        <th>Email</th>
                        <th>phone</th>
                        <th>SR name</th>
                        <th>Location</th>
                        <th>Status</th>
                        <th class="action-th">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in tableItems" :key="i">

                        <td>
                            {{ item.contact_profile.full_name }}
                        </td>

                        <td>
                            {{ item.contact_profile.full_address }}
                        </td>

                        <td>
                            {{ item.contact_profile.email }}
                        </td>

                        <td>
                            {{ item.contact_profile.phone }}
                        </td>

                        <td>
                            {{ item.sr_name }}
                        </td>

                        <td>
                            {{ item.area && item.area.name }}
                        </td>

                        <td>
                            <span class="badge rounded-pill me-1" :class="'badge-light-' + (item.status == 'active' ? 'success' : 'danger')">
                                {{ upperFirst(item.status) }}
                            </span>
                        </td>

                        <td>
                            <div class="action">
                                <button @click="editDealer(item)" class="btn btn-primary btn-sm">
                                    <i class="fas fa-eye"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        <p v-if="!tableItems.length" class="text-center mt-5">No data</p>
    </div>
</div>
</template>

<script setup>
    import { useRoute, useRouter } from "vue-router";
    import { upperFirst } from 'lodash';

    const $route = useRoute();
    const $router = useRouter();

    const props = defineProps({
        tableItems: {
            type: Array,
        },
        onEditSR: Function,
    })

    const emit = defineEmits(['onEditSR']);

    const editDealer = (item) => {
        emit('onEditSR', item)
    }

</script>

<style scoped>
.action-th{
    width: 15%;
    text-align: center;
}
.name{
    width: 15%;
}
.action{
    text-align: center;
}
</style>