import Network from '@/services/network'

export default function handleCrm(){

    const network = new Network;

    const fetchAreaList = (query) => {
        return network.api('get', `/crm/area-list` + query);
    }

    //Sales Representative
    const fetchAllSR = (query) => {
        return network.api('get', `/crm/sale-representative/all-sr` + query);
    }
    const storeSR = (data) => {
        return network.api('post', '/crm/sale-representative/store-sr', data);
    }
    const updateSr = (data, id) => {
        return network.api('put', '/crm/sale-representative/update-sr/' + id, data);
    }
    const fetchSrList = (query) => {
        return network.api('get', '/crm/sale-representative/list' + query);
    }
    const fetchSingleSr = (query, id) => {
        return network.api('get', '/crm/sale-representative/show/' + id + query);
    }
    
    //Dealers
    const fetchAllDealers = (query) => {
        return network.api('get', `/crm/dealer/all-dealers` + query);
    }
    const fetchDealerList = (query) => {
        return network.api('get', '/crm/dealer/list' + query);
    }
    const storeDealer = (data) => {
        return network.api('post', '/crm/dealer/store-dealer', data);
    }
    const updateDealer = (data, id) => {
        return network.api('put', '/crm/dealer/update-dealer/' + id, data);
    }
    const fetchSingleDealer = (query, id) => {
        return network.api('get', '/crm/dealer/show/' + id + query);
    }

    //Visit Plan Schedule
    const storeVisitPlan = (data) => {
        return network.api('post', '/crm/visit-plan/store-visit-plan', data);
    }
    const updateVisitPlan = (data, id) => {
        return network.api('put', '/crm/visit-plan/update-visit-plan/' + id, data);
    }
    const fetchVisitPlanList = (query) => {
        return network.api('get', '/crm/visit-plan/list' + query);
    }
    const fetchSingleVisitPlan = (query, id) => {
        return network.api('get', '/crm/visit-plan/show/' + id + query);
    }

    //Visit Status
    const fetchVisitClientList = (query, id) => {
        return network.api('get', '/crm/visit-status/visit-clients/' + id + query);
    }    
    const fetchActivityLogStatus = (query) => {
        return network.api('get', '/crm/visit-status/log-status' + query);
    }
    const updateVisitStatus = (data, id) => {
        return network.api('put', '/crm/visit-status/update-visit-status/' + id, data);
    }
    const storeVisitPlanLog = (data) => {
        return network.api('post', '/crm/visit-status/store-visit-log', data);
    }
    const updateVisitPlanLog = (data, id) => {
        return network.api('put', '/crm/visit-status/update-visit-log/' + id, data);
    }

    return {
        fetchAreaList,

        //Sales Representative
        fetchAllSR,
        storeSR,
        updateSr,
        fetchSrList,
        fetchSingleSr,

        //Dealer
        fetchAllDealers,
        fetchDealerList,
        storeDealer,
        updateDealer,
        fetchSingleDealer,

        //Visit Plan Schedule
        storeVisitPlan,
        updateVisitPlan,
        fetchVisitPlanList,
        fetchSingleVisitPlan,

        //Visit Status
        fetchVisitClientList,
        updateVisitStatus,
        fetchActivityLogStatus,
        storeVisitPlanLog,
        updateVisitPlanLog
    }
}